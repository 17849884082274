export const SET_OBJECT_LIST = 'SET_OBJECT_LIST';
export const SET_PAGE = 'SET_PAGE';
export const SET_ACTIVE_LAYER = 'SET_ACTIVE_LAYER';
export const SET_SHOW_FILTER = 'SET_SHOW_FILTER';
export const SET_SHOW_OBJECTS = 'SET_SHOW_OBJECTS';
export const SET_SEARCH_BY_TITLE = 'SET_SEARCH_BY_TITLE';
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES';
export const SET_OBJECT_LIST_LOADER = 'SET_OBJECT_LIST_LOADER';
export const SET_OBJECT = 'SET_OBJECT';
export const SET_OBJECT_LAYER = 'SET_OBJECT_LAYER';
export const SET_SHOW_PASSPORT = 'SET_SHOW_PASSPORT';
export const SET_PASSPORT_OPEN_FROM_LIST = 'SET_PASSPORT_OPEN_FROM_LIST';
export const SET_CATALOGS = 'SET_CATALOGS';
export const SET_LAYERS_LOADING = 'SET_LAYERS_LOADING';
export const SET_LAYERS = 'SET_LAYERS';
export const CHANGE_LAYER = 'CHANGE_LAYER';