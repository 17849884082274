import TileLayer from "ol/layer/Tile.js";
import TileWMS from "ol/source/TileWMS.js";
import { Vector as VectorSource } from "ol/source.js";
import { Vector as VectorLayer } from "ol/layer.js";
import GeoJSON from "ol/format/GeoJSON.js";
const createLayer = (layer, filter) =>
  new TileLayer({
    name: layer,
    source: new TileWMS({
      url: `${process.env.GEOSERVER_URL}/wms`,
      params: {
        LAYERS: layer,
        TILED: true,
        VERSION: "1.1.1",
        SRS: "EPSG:3857",
        BBOX: "{bbox-epsg-3857}",
        WIDTH: 256,
        HEIGHT: 256,
        CQL_FILTER: filter,
        T: new Date().getTime(),
      },
      serverType: "geoserver",
    }),
    zIndex: 97,
  });

import {
  GET_LAYER_OBJECT_LIST,
  APPLY_FILTER,
  SELECT_OBJECT,
  CLEAR_OBJECT_DATA,
  GET_CATALOGS,
  GET_LAYERS,
} from "./action-types";

import {
  SET_OBJECT_LIST,
  SET_SHOW_FILTER,
  SET_SHOW_OBJECTS,
  SET_FILTER_VALUES,
  SET_OBJECT_LIST_LOADER,
  SET_OBJECT_LAYER,
  SET_OBJECT,
  SET_SHOW_PASSPORT,
  SET_PASSPORT_OPEN_FROM_LIST,
  SET_CATALOGS,
  SET_PAGE,
  SET_LAYERS,
  SET_LAYERS_LOADING,
  CHANGE_LAYER,
} from "./mutation-types";

export default {
  generateCqlQuery(filters) {
    let cql = [];

    if (filters.title) {
      cql.push(`title ILIKE '%${filters.title}%'`);
    }
    if (filters.point_type_id) {
      cql.push(`point_type_id = ${filters.point_type_id}`);
    }
    if (filters.coordinate_accuracy) {
      cql.push(`coordinate_accuracy = ${filters.coordinate_accuracy}`);
    }
    if (filters.height_accuracy) {
      cql.push(`height_accuracy = ${filters.height_accuracy}`);
    }
    if (filters.mount_type_id) {
      cql.push(`mount_type_id = ${filters.mount_type_id}`);
    }

    return cql.length > 0 ? cql.join(" AND ") : "";
  },
  async [GET_LAYER_OBJECT_LIST]({ commit, rootState, state, dispatch }) {
    commit(SET_OBJECT_LIST_LOADER, true);
    commit(SET_SHOW_OBJECTS, true);
    let layer = state.activeLayer;
    let params = {
      layer,
      page: state.currentPage,
    };
    if (state.searchByTitle) {
      params.title = state.searchByTitle;
    }
    if (state.filterValues?.point_type_id) {
      params.point_type_id = state.filterValues?.point_type_id;
    }
    if (state.filterValues?.coordinate_accuracy_class_id) {
      params.coordinate_accuracy_class_id =
        state.filterValues?.coordinate_accuracy_class_id;
    }
    if (state.filterValues?.height_accuracy_class_id) {
      params.height_accuracy_class_id =
        state.filterValues?.height_accuracy_class_id;
    }
    if (state.filterValues?.mount_type_id) {
      params.mount_type_id = state.filterValues?.mount_type_id;
    }
    const oldOlLayer = rootState.geoportal.layers[layer].olLayer;
    return this.$axios
      .$get("api/control-point-data", { params })
      .then((response) => {
        commit(SET_OBJECT_LIST, response);
        let activeLayers = rootState.geoportal.activeLayers;
        let cql = [];
        if (params.title) {
          cql.push(`title ILIKE '%${params.title}%'`);
        }
        if (params.point_type_id) {
          cql.push(`point_type_id = ${params.point_type_id}`);
        }
        if (params.coordinate_accuracy_class_id) {
          cql.push(
            `accuracy_class_id = ${params.coordinate_accuracy_class_id}`
          );
        }
        if (params.height_accuracy_class_id) {
          cql.push(
            `height_accuracy_class_id = ${params.height_accuracy_class_id}`
          );
        }
        if (params.mount_type_id) {
          cql.push(`mount_type_id = ${params.mount_type_id}`);
        }
        if (cql.length) {
          cql = cql.join(" AND ");
        } else {
          cql = null;
        }
        let filteredLayer = createLayer(layer, cql);
        commit(CHANGE_LAYER, { layerName: layer, olLayer: filteredLayer });

        if (activeLayers.includes(layer)) {
          rootState.geoportal.map.removeLayer(oldOlLayer);
          rootState.geoportal.map.addLayer(filteredLayer);
        } else {
          rootState.geoportal.map.addLayer(filteredLayer);
          commit('geoportal/ADD_ACTIVE_LAYER', layer, { root: true });
        }
      })
      .catch(() => alert("Ошибка загрузки данных реестра"))
      .finally(() => {
        commit(SET_OBJECT_LIST_LOADER, false);
      });
  },
  [SELECT_OBJECT]({ commit, rootState, state }, { id }) {
    let map = rootState.geoportal.map;
    if (state.selectedObjectLayer) {
      map.removeLayer(state.selectedObjectLayer);
      commit(SET_OBJECT_LAYER, null);
    }
    let params = {
      id,
    };
    return this.$axios
      .$get("api/control-point", { params })
      .then((response) => {
        let object = response?.[0];
        console.log("object", object);
        if (object.geojson) {
          let source = new VectorSource({ wrapX: false });
          let geo = { ...object.geojson };
          let featObj = new GeoJSON().readFeature(geo, {
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857",
          });
          source.addFeature(featObj);
          let layer = new VectorLayer({ source: source });
          map.addLayer(layer);
          commit(SET_OBJECT_LAYER, layer);
          map.getView().fit(source.getExtent(), {
            padding: [100, 100, 100, 100],
          });
        }
        commit(SET_SHOW_PASSPORT, true);
        commit(SET_OBJECT, object);
        commit(SET_SHOW_OBJECTS, false);
        commit(SET_SHOW_PASSPORT, true);
      })
      .catch(() => alert("Ошибка загрузки данных объекта"))
      .finally(() => {
        commit(SET_OBJECT_LIST_LOADER, false);
      });
  },
  [CLEAR_OBJECT_DATA]({ commit, state, rootState }) {
    let map = rootState.geoportal.map;

    if (state.selectedObjectLayer) {
      map.removeLayer(state.selectedObjectLayer);
    }
    commit(SET_SHOW_PASSPORT, false);
    commit(SET_OBJECT_LAYER, null);
    if (state.passportOpenFromList) {
      commit(SET_SHOW_OBJECTS, true);
      commit(SET_PASSPORT_OPEN_FROM_LIST, false);
    }
  },
  [GET_CATALOGS]({ commit }) {
    return this.$axios
      .$get("api/control-point-refs")
      .then((response) => {
        commit(SET_CATALOGS, response);
      })
      .catch(() => alert("Ошибка загрузки данных каталогов"));
  },
  [APPLY_FILTER]({ commit, dispatch }, { filters }) {
    commit(SET_FILTER_VALUES, filters);
    commit(SET_PAGE, 1);
    dispatch(GET_LAYER_OBJECT_LIST);
    commit(SET_SHOW_FILTER, false);
  },
  [GET_LAYERS]({ commit }) {
    commit(SET_LAYERS_LOADING, true);

    return this.$axios
      .$get("api/geoportal/layers")
      .then((response) => {
        const olLayers = _.chain(response ?? [])
          .keyBy((l) => l.geoserver_name)
          .mapValues((layer, layerId) => ({
            ...layer,
            olLayer: createLayer(layerId, null),
          }))
          .value();

        commit(SET_LAYERS, olLayers);

        return response;
      })
      .catch(() => alert("Ошибка загрузки данных!"))
      .finally(() => commit(SET_LAYERS_LOADING, false));
  },
};
