import Vue from 'vue'
import {
  SET_OBJECT_LIST,
  SET_ACTIVE_LAYER,
  SET_SHOW_FILTER,
  SET_SHOW_OBJECTS,
  SET_SEARCH_BY_TITLE,
  SET_FILTER_VALUES,
  SET_OBJECT_LIST_LOADER,
  SET_OBJECT_LAYER,
  SET_OBJECT,
  SET_SHOW_PASSPORT,
  SET_PASSPORT_OPEN_FROM_LIST,
  SET_CATALOGS,
  SET_PAGE,
  SET_LAYERS_LOADING,
  SET_LAYERS,
  CHANGE_LAYER
} from "./mutation-types";
export default {
  [SET_OBJECT_LAYER](state, selectedObjectLayer) {
    state.selectedObjectLayer = selectedObjectLayer;
  },
  [SET_PAGE](state, page) {
    state.currentPage = page;
  },
  [SET_CATALOGS](state, catalogs) {
    state.catalogs = catalogs;
  },
  [SET_OBJECT](state, selectedObject) {
    state.selectedObject = selectedObject;
  },
  [SET_OBJECT_LIST](state, objectList) {
    state.objectList = objectList;
  },
  [SET_OBJECT_LIST_LOADER](state, isObjectListLoader) {
    state.isObjectListLoader = isObjectListLoader;
  },
  [SET_ACTIVE_LAYER](state, activeLayer) {
    state.activeLayer = activeLayer;
  },
  [SET_SHOW_FILTER](state, isShowFilter) {
    state.isShowFilter = isShowFilter;
  },
  [SET_SHOW_OBJECTS](state, isShowObjectList) {
    state.isShowObjectList = isShowObjectList;
  },
  [SET_SEARCH_BY_TITLE](state, searchByTitle) {
    state.searchByTitle = searchByTitle;
  },
  [SET_FILTER_VALUES](state, filterValues) {
    state.filterValues = filterValues;
  },
  [SET_SHOW_PASSPORT](state, isShowPassport) {
    state.isShowPassport = isShowPassport;
  },
  [SET_PASSPORT_OPEN_FROM_LIST](state, passportOpenFromList) {
    state.passportOpenFromList = passportOpenFromList;
  },
  [SET_LAYERS_LOADING](state, isLoading) {
    state.islayersLoading = isLoading;
  },
  [SET_LAYERS](state, layers) {
    state.layers = layers;
  },
  [CHANGE_LAYER](state, data) {
    if (!state.layers[data.layerName]) {
      Vue.set(state.layers, data.layerName, {});
    }
  
    Vue.set(state.layers[data.layerName], 'olLayer', data.olLayer);
  }
};
