export default () => ({
    objectList: {},
    currentPage: 1,
    filterValues: {},
    isShowObjectList: false,
    isShowFilter: false,
    activeLayer: null,
    searchByTitle: null,
    filteredOLayer: null,
    isObjectListLoader: false,
    selectedObject: null,
    selectedObjectLayer: null,
    isShowPassport: false,
    passportOpenFromList: false,
    catalogs: {},
    layers: {},
    islayersLoading: false
});